.loading-spinner-fau {
    width: 128px;
    height: 128px;
    -webkit-animation:spin 7s linear infinite;
    -moz-animation:spin 7s linear infinite;
    animation:spin 7s linear infinite;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }